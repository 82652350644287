export function hasHigherDiscount(name: string = "") {
  const departments = [
    "physiotherapie",
    "mentaltraining",
    "psychotherapie",
    "ernährungsberatung",
    "osteopathie",
    "diätologie",
    "heilmassage",
    "wundmanagement",
  ];

  return departments.includes(name.toLowerCase());
}
